<template>
  <el-table :data="list" style="width: 100%" border :height="contentStyleObj" v-loading="loading"
    :row-class-name="tableRowClassName" :span-method="arraySpanMethod">
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column prop="itemName" label="项目大类" width="105" align="left" v-if="this.xinchou">
    </el-table-column>
    <el-table-column prop="name" label="项目名称" width="195" align="left" show-overflow-tooltip>
      <template #default="scope">
        {{ scope.row.type == "code_bsbcwbb" ? "申报财务报表" : scope.row.name }}
      </template>
    </el-table-column>
    <el-table-column prop="remark" label="设置" width="585" align="left">
      <template #default="scope">
        <subject-list v-if="scope.row.valueType == 'select'" :codes="$findCode(scope.row.option)"
          v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId" :width="500"
          :addNotShow="scope.row.name == '代扣社保' ? true : false"></subject-list>
        <el-radio-group v-if="scope.row.valueType == 'radio'" v-model="scope.row.value" size="small">
          <el-radio v-for="item1 in scope.row.option" :label="item1.value" :key="item1.index">{{
            scope.row.type == "code_bsbcwbb"
              ? item1.name == "是"
                ? "不申报"
                : "申报"
              : item1.name
          }}</el-radio>
        </el-radio-group>
        <el-input v-if="scope.row.valueType == 'input'" size="small" v-model="scope.row.value"
          style="width: 500px"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="参考" min-width="170" align="left" show-overflow-tooltip>
      <template #default="scope">
        {{ scope.row.ck }}
      </template>
    </el-table-column>
    <el-table-column prop="remark" label="备注" min-width="280" align="left" show-overflow-tooltip>
      <template #default="scope">
        <span v-if="scope.row.remark" :class="from == 'kjbb' ? 'text_initial' : ''">{{ scope.row.remark }}</span>
        <span v-else>&nbsp;&nbsp;</span>
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 20px; text-align: right">
    <el-button type="primary" @click="batchSave" size="small" :loading="btnLoading"
      :disabled="!$buttonStatus('kmpz_plbc')"><i class="iconfont icon-baocun"></i>批量保存</el-button>
  </div>
</template>

<script>
import { getComSetList, setComSets } from "@/api/company";
export default {
  name: "settings",
  props: {
    type: {
      type: Array,
      default: [],
    },
    choiceSetting: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      dialogTableVisible: false,
      loading: false,
      btnLoading: false,
      xinchou: true,
      contentStyleObj: {},
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(210);
  },
  methods: {
    getList() {
      this.loading = true;
      let param = {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        types: this.type,
      };
      getComSetList(param).then((res) => {
        this.loading = false;
        this.list = res.data.data.list ? res.data.data.list : [];
        this.handleTable();
      });
    },
    batchSave() {
      if (this.$checkSettleStatus()) return;
      this.btnLoading = true;
      setComSets(this.list).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogTableVisible = false;
          this.getList();
        } else {
          this.$qzfMessage("请查看设置项目", 1);
        }
      });
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.xinchou) {
        if (columnIndex === 0) {
          const prevRow = this.list[rowIndex - 1];
          if (prevRow && row.itemName === prevRow.itemName) {
            return { rowspan: 0, colspan: 1 };
          } else {
            let rowspan = 1;
            for (let i = rowIndex + 1; i < this.list.length; i++) {
              if (this.list[i].itemName === row.itemName) {
                rowspan++;
              } else {
                break;
              }
            }
            return { rowspan, colspan: 1 };
          }
        }
        return { rowspan: 1, colspan: 1 };
      }

    },
    // 样式
    tableRowClassName({ row, rowIndex }) {
      if (row.type == this.choiceSetting) {
        return 'warning-row';
      }
      return '';
    },
    handleTable() {
      const categoryMapping = {
        basic: [
          { itemName: "其他", types: ["code_fee_1001", "code_cash_1001", "code_bnlr", "code_wfplr", "code_jmtds", "code_sfxy", "code_bnlr_xdx", "code_bnlr_fxdx", "code_shebao_password"] },
          { itemName: "增值税", types: ["code_jxse", "code_xxse", "code_wrzjxs", "code_sfxsbdc"] },
          { itemName: "凭证", types: ["code_summary_inventory", "code_inventory_qy", "code_inventory_5001"] },
          { itemName: "存货", types: ["code_inventory_not_use_spec", "code_inventory_count", "code_inventory_zg", "code_inventroy_Aa", "code_inventroy_unit"] },
          { itemName: "会计报表", types: ["code_bsbcwbb", "code_xjllb_sb", "code_zcfz_sj"] },
        ],
        swbb: [
          { itemName: "计提凭证设置", types: ["code_jt_voucher"] },
          { itemName: "增值税", types: ["code_jjdj", "code_jjdj_subject_code", "code_jjdj_kcs", "code_xgmdkfpbjsfjs", "code_vat_bc", "code_vat_ckts", "code_fgs", "code_jm_tax"] },
          { itemName: "印花税", types: ["code_yhsjsjshj", "code_yhsfu", "code_yh_in", "code_yh0"] },
          { itemName: "企业所得税", types: ["code_sffgs", "code_qysds", "code_gxqy", "code_sds_ms"] },
          { itemName: "工会经费", types: ["code_ghjf_gz", "code_ghjf", "code_ghjf_0"] },
          { itemName: "水利建设收入", types: ["code_sl_0"] },
          { itemName: "文化事业建设费", types: ["code_whsyjsf_0"] },
          { itemName: "取数设置", types: ["code_tax_sj"] }
        ],
        salary: [
          { itemName: "薪酬", types: [] }
        ],
        kjbb: [
          { itemName: "应交税费", types: ["code_zcfz2221_cfl"] },
          { itemName: "会计报表", types: ["code_zcfz_cfl"] },
        ],
        qt: [
          { itemName: "其他设置", types: [] }
        ]
      };
      const categories = categoryMapping[this.from] || [];
      const itemNameToIndex = {};
      categories.forEach((category, index) => {
        itemNameToIndex[category.itemName] = index;
      });
      this.list.forEach(item => {
        categories.forEach(category => {
          if (category.types.includes(item.type)) {
            item.itemName = category.itemName;
          }
        });
      });
      this.list.sort((a, b) => {
        return (itemNameToIndex[a.itemName] || Infinity) - (itemNameToIndex[b.itemName] || Infinity);
      });
      if (this.from === "salary" || this.from === "kjbb") {
        this.xinchou = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-radio-group {
  white-space: pre-wrap !important;
  text-align: left;
}

:deep(.el-radio) {
  margin-right: 14px;
}

.text_initial {
  // white-space: initial;
  white-space: pre-wrap;
  display: inline-block;
}
</style>

<style>
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
