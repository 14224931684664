<template>
  <div class="app-container">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span
            >家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a
              ><span>{{ info.countErr }}</span
              >家</a
            >
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div class="each_text">
            <div>已确认</div>
            <a>
              <span>{{ info.countYqr }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period
          v-model:period="listQuery.period"
          :clearable="false"
          style="width: 100px"
          @change="getNewList"
        ></qzf-search-period>
      </div>
    </div>
    <div class="content_select">
      <div class="left_select">
        <el-button
          icon="RefreshRight"
          size="small"
          @click="getList()"
          style="margin-right: 5px"
        ></el-button>
        <el-input
          placeholder="请输入企业名称"
          v-model="listQuery.name"
          style="width: 160px"
          size="small"
          @keyup.enter="getList"
          clearable
        /><el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin: 11px 0px 0px 15px; width: 300px"
            label-width="95px"
          >
            <el-form-item label="纳税人类型：">
              <selecttaxtype
                v-model:type="listQuery.type"
                style="width: 140px"
              ></selecttaxtype>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity
                v-model:citys="listQuery.districtCode"
                style="width: 140px"
              ></selectcity>
            </el-form-item>
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.not0" style="margin-left:5px" size="small" @change="getList">
          <el-radio-button :label="0" >全部</el-radio-button>
          <el-radio-button :label="1" >有税款</el-radio-button>
          <el-radio-button :label="2" >无税款</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button
          @click="confirmAll()"
          type="primary"
          size="small"
          plain
          icon="Checked"
          :disabled="!$buttonStatus('zdjz_skqr')"
          >确认
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="copyTax"
          :disabled="!$buttonStatus('sktj_fzsk')"
          icon="DocumentCopy"
          >复制税款
        </el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        @selection-change="handleSelectionChange"
        stripe
        @sort-change="sortChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column
          label="编号"
          align="center"
          width="80"
          fixed="left"
          prop="sort"
          sortable="custom"
        >
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="企业名称"
          min-width="280"
          fixed="left"
        >
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false" />
          </template>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="税局"
          width="65"
          align="center"
        >
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total"
          label="总税金"
          sortable
          align="right"
          min-width="90"
        />
        <el-table-column
          prop="zzs"
          label="增值税"
          sortable
          align="right"
          min-width="90"
        />
        <el-table-column
          prop="fjs"
          label="附加税"
          sortable
          align="right"
          min-width="90"
        />
        <el-table-column
          prop="deed"
          label="行为税"
          sortable
          align="right"
          min-width="90"
        />
        <el-table-column
          prop="gs"
          label="个税"
          sortable
          align="right"
          min-width="90"
        />
        <el-table-column
          prop="qysds"
          label="企业所得税"
          sortable
          align="right"
          min-width="115"
        />
        <!-- <el-table-column
          prop="sb"
          label="社保"
          sortable
          align="right"
          min-width="90"
        /> -->
        <el-table-column
          prop="dqde"
          label="定期定额"
          sortable
          align="right"
          min-width="95"
        />
        <el-table-column
          prop="qt"
          label="其他税种合计"
          align="left"
          min-width="140"
        >
          <template #default="scope">
            <div class="item_icon" style="justify-content: space-between">
              <div>
                {{
                  (
                    Number(scope.row.scjysds) +
                    Number(scope.row.whsyjss) +
                    Number(scope.row.ghjf) +
                    Number(scope.row.ljf) +
                    Number(scope.row.xfs) +
                    Number(scope.row.cbj) +
                    Number(scope.row.sl) +
                    Number(scope.row.hbs) +
                    Number(scope.row.qt)
                  ).toFixed(2)
                }}
              </div>
              <el-button @click="checkDetail(scope.row)" size="small" link
                >详情</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="确认状态" width="90">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$filterAutoStatusIcon(scope.row.skqr_status)"></i>
              <span>{{
                $filterAutoStatusText(scope.row.skqr_status, "qr")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="确认人" width="90">
          <template #default="scope">
            <div v-if="scope.row.skqr_status == 3">
              {{
                scope.row.taxConfirmUserName
                  ? scope.row.taxConfirmUserName
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          min-width="130"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              size="small"
              plain
              type="primary"
              :disabled="scope.row.skqr_status != 2 || !$buttonStatus('zdjz_skqr')"
              @click="confirm(scope.row)"
              icon="Checked"
            >
              确认
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogVisible"
    title="税款详情"
    width="500px"
  >
    <table class="content content_head" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td style="width: 60%">税种</td>
        <td style="width: 40%">税额</td>
      </tr>
      <tr>
        <td>生产经营所得税</td>
        <td>{{ taxInfo.scjysds }}</td>
      </tr>
      <tr>
        <td>文化事业建设费</td>
        <td>{{ taxInfo.scjysds }}</td>
      </tr>
      <tr>
        <td>工会经费</td>
        <td>{{ taxInfo.ghjf }}</td>
      </tr>
      <tr>
        <td>垃圾费</td>
        <td>{{ taxInfo.ljf }}</td>
      </tr>
      <tr>
        <td>消费税</td>
        <td>{{ taxInfo.xfs }}</td>
      </tr>
      <tr>
        <td>残保金</td>
        <td>{{ taxInfo.cbj }}</td>
      </tr>
      <tr>
        <td>水利基金</td>
        <td>{{ taxInfo.sl }}</td>
      </tr>
      <tr>
        <td>环保税</td>
        <td>{{ taxInfo.hbs }}</td>
      </tr>
      <tr>
        <td>其他税种</td>
        <td>{{ taxInfo.qt }}</td>
      </tr>
    </table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogVisible = false"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <abnormalCom ref="abnormalCom" />
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import { companyTaxAmountList } from "@/api/company";
import { wbVoucherFxOther } from "@/api/export";
import { confirmAutomatic, automaticStatTax } from "@/api/automatic";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import abnormalCom from "./components/abnormalCom.vue";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";

export default {
  name: "taxConfirm",
  components: {
    selectcity,
    selecttaxtype,
    abnormalCom,
    TableSortCell,
    TagNameCopy,
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        period: currentAccountPeriod(),
        type: "",
        autoMatic: 1,
        not0: 0,
      },
      loading: false,
      tableData: [],
      total: 0,
      selects: [],
      dialogVisible: false,
      taxInfo: {},
      info: {},
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(260);
  },
  mounted() {
    this.getList();
    this.getInfo();
  },

  methods: {
    getList() {
      this.loading = true;
      companyTaxAmountList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    getInfo() {
      automaticStatTax(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.info = res.data.data;
        }
      });
    },
    getNewList() {
      this.getList();
      this.getInfo();
    },
    getNewListTotal(e) {
      this.listQuery.taxStatusTotal = e ? e : null;
      this.getNewList();
    },
    getWjz() {
      this.$refs.abnormalCom.init(this.listQuery.period);
    },
    // 筛选打印
    handleSelectionChange(val) {
      this.selects = val;
    },
    //复制税款
    copyTax() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      // 基础字符串数组
      let rows = [];
      // 使用对象键值对来简化税种的判断和拼接
      const taxes = {
        zzs: "增值税",
        fjs: "附加税",
        deed: "行为税",
        gs: "个税",
        qysds: "企业所得税",
        sb: "社保",
        dqde: "定期定额",
        scjysds: "生产经营所得税",
        whsyjss: "文化事业建设费",
        ghjf: "工会经费",
        ljf: "垃圾费",
        xfs: "消费税",
        cbj: "残保金",
        sl: "水利基金",
        hbs: "环保税",
      };
      // 遍历selects数组
      this.selects.forEach((v) => {
        let row = `您的企业「${v.name}」${this.listQuery.period}账期`;
        if (v.total && v.total !== "0.00") {
          row += "税款如下：";
          Object.keys(taxes).forEach((key) => {
            const taxName = taxes[key];
            const taxValue = v[key];
            if (taxValue && taxValue !== "0" && taxValue !== "0.00" && taxName != '社保') {
              row += `\r${taxName}：${taxValue}`;
            }
          });
          row += `\r总计：${v.total}`;
        } else {
          row += "账期暂无税款";
        }
        rows.push(row);
      });
      // 将所有行拼接成最终字符串，注意最后的提示信息
      let str =
        rows.join("\r") +
        `\r${this.listQuery.period}账期税款如上，请确认，如有疑问请随时沟通`;
      this.$copyComName(str);
    },
    //确认
    confirm(row) {
      this.$confirm("确定要确认税款吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        confirmAutomatic([
          {
            comId: row.comId,
            period: this.listQuery.period,
            type: "skqr",
          },
        ]).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("操作成功");
            this.getList();
            this.$bus.emit("confirmStatusUpdate");
          }
        });
      });
    },
    //批量确认
    confirmAll() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let str = "";
      this.selects.map((v) => {
        if (v.skqr_status != 2) {
          str = v.name + "不是待确认状态不可确认，请重新选择！";
        }
      });
      if (str) {
        this.$qzfMessage(str, 1);
        return;
      }
      this.$confirm("确定要批量确认税款吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        this.selects.map((v) => {
          ids.push({
            comId: v.comId,
            period: this.listQuery.period,
            type: "skqr",
          });
        });
        confirmAutomatic(ids).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("操作成功");
            this.getList();
            this.$bus.emit("confirmStatusUpdate");
          }
        });
      });
    },
    //详情
    checkDetail(row) {
      this.taxInfo = row;
      this.dialogVisible = true;
    },
    // 表格排序
    sortChange(data) {
      if (data.column.label == "编号") {
        if (data.order == "descending") {
          this.listQuery.desc = 1;
        } else {
          this.listQuery.desc = 0;
        }
        this.getList();
      }
    },
    //重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        period: currentAccountPeriod(),
        type: "",
        autoMatic: 1,
        not0: 0,
      };
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.content_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  .left_select{
    display: flex;
    align-items: center;
  }
}
.item_icon {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;
  i {
    font-size: 13px;
    margin-right: 2px;
  }
}
.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 25px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
    text-align: center;
  }
}
</style>
